<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <ParticlesBG mgs="test" /> 
    <section id="page-content">
      
      
        <a id="services"></a>
        <Services />


    </section>
    <BodyFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import ParticlesBG from '@/components/ParticlesBG.vue'
import About from '@/components/About.vue';
import BodyFooter from '@/components/BodyFooter.vue';
import Services from '@/components/Services.vue'

export default {
  name: 'HomeView',
  components: {    
    ParticlesBG,        
    Services,
    BodyFooter
  }
}
</script>

<style scoped>
#page-content {
  position: relative;
  top: -8rem; 
   
  width: 96vw;  
  left: 2vw;    
}
</style>
