<template>
    <div id="particles-js">
        <div class="hero">
            <content>
                <img src="../assets/logo.png">
                <h1>Aeonsoft<span class="roboto-thin">Tecnologia</span></h1>
                <div class="slider">
                    <h2>desenvolvimento de sistemas<span>, websites, apps, e games </span></h2>
                </div>
            </content>
            <button type="button" v-on:click.prevent="openWhatsApp">Solicite um orçamento</button>
        </div>
    </div>
    <div id="particles-separator"></div>
</template>

<script>
export default {
    name: 'ParticlesBG',
    props: {
        msg: String
    },

    methods: {
        openWhatsApp: function () {
            const url = `https://wa.me/5511973811720`
            window.open(url, '_blank')
        }
    },

    mounted() {
        console.log('setup')
        particlesJS.load('particles-js', 'assets/particles.json', function () {
            console.log('callback - particles.js config loaded');
        })
    }
}
</script>

<style>
:root {
    --hero-height: 100px;
    --particles-hight: 45rem;
}
</style>
<style scoped>
@keyframes fadeInAndTranslate {
    from {
        opacity: 0;
        transform: translate(-50%, 0) scale(1.03);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }
}

#particles-js {
    position: absolute;
    width: 100vw;
    height: var(--particles-hight);
    background-color: #02021a;
    background: linear-gradient(#020213, var(--brand-blue));
}

#particles-separator {
    padding-top: var(--particles-hight);
    /* border: 5px solid green; */
}

.hero {
    position: absolute;
    top: calc((var(--particles-hight)/2) - (var(--hero-height)));
    left: 50%;
    transform: translate(-50%, 0);
    animation: fadeInAndTranslate 0.75s ease-in;

    content {
        display: flex;
        align-items: center;
        width: auto;

        img {
            height: calc(var(--hero-height) * 1.3);
            margin: 0;
            padding: 0;
        }

        h1 {
            margin: 0;
            padding: 0;
            display: inline;
            margin-top: -1.05rem;

            font-family: "Sora", sans-serif;
            font-optical-sizing: auto;
            font-weight: bold;
            font-style: normal;
            font-size: 72px;
            color: white;

            span {
                opacity: .5;
            }
        }

        .slider {
            font-family: "Roboto Condensed", sans-serif;
            font-size: 1.2rem;
            text-align: left;
            color: white;
            animation: fadeIn 1s ease-in;
            margin-top: -.7rem;

            position: absolute;
            left: 8.1rem;
            top: 6rem;
            min-width: 30rem;

            h2 {
                /* font-weight: 100; */
                opacity: 1;
            }

            span {
                font-weight: 100;
                opacity: .5;
            }

        }
    }

    button {
        font-size: 1rem;
        margin-top: 5rem;
        padding: .6rem;
        width: 12rem;
        cursor: pointer;
        border: none;
        color: var(--brand-blue);
        animation: fadeIn 1s ease-in;
        border-radius: .4rem;
        background: linear-gradient(rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1));                

        &:hover {                        
            background: linear-gradient(rgba(255, 255, 255, 1),
                    rgba(255, 255, 255, .8));

        }
    }
}

@media screen and ((max-width : 320px) or (max-width : 600px) or (max-width : 992px)) {
    .hero {
        content {
            display: inline-block;

            img {
                font-size: 14vw;
                height: 22vw;
            }

            h1 {
                font-size: 9vw;

                span {
                    display: inline-block;
                    display: none;
                }
            }

            .slider {
                /* border: 2px solid red; */

                position: relative;
                left: 0;
                top: 0;
                margin: 0;
                min-width: 20rem;
                text-align: center;
                font-size: 1rem;

                span {
                    /* display: none; */
                }
            }
        }

        button {

            width: 40vw;
            font-size: 1.2rem;
        }


    }
}

@media screen and ((max-width : 320px) or (max-width : 490px)) {
    .hero {
        top: calc(calc((var(--particles-hight)/2) - (var(--hero-height))) - 2rem);

        text-align: center;

        content {
            img {
                display: block;
                margin: 0 auto;
            }

        }

        button {
            font-size: 1rem;
            width: 70vw;
        }

    }

    

}
</style>
