<template>
    <section class="services">
        <!-- <h1>Services</h1> -->
        <div class="cards">
            <div class="card">
                <header>Sistemas e Aplicativos</header>
                <content>card content
                    lkadsahj sad.nsadçs adsad
                    k sakdjsa sdkjhds lfkds
                </content>
            </div>
            <div class="card">
                <header>Websites</header>
                <content>card content
                    lkadsahj sad.nsadçs adsad
                    k sakdjsa sdkjhds lfkds
                </content>
            </div>
            <div class="card">
                <header>Games</header>
                <content>card content
                    lkadsahj sad.nsadçs adsad
                    k sakdjsa sdkjhds lfkds
                </content>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'services'
}
</script>

<style scoped>
.services {
    display: none;
    color: white;    

    & .cards {
        display: flex;
        flex-flow: row nowrap;        
        justify-content: center;
        column-gap: 1rem;
        animation: fadeIn 0.751s ease-in;

        .card {                        
            width: 25rem;
            min-width: 20rem;
            background-color: rgba(255, 255, 255, .08);
            border-radius: .6rem;
            padding: 1rem;
            
            header {
                font-size: 1.2rem;
                text-align: left;
            }
            
            content {
                display: block;
                min-height: 20rem;
            }
        }
    }
}
</style>